import React, { useState } from "react";
import axios from "axios"; // Ensure to install axios using npm install axios

function QuestionAnswering() {
    const [question, setQuestion] = useState("");
    const [accessEmail, setAccessEmail] = useState("");
    const [accessChief, setAccessChief] = useState("");
    const [notes, setNotes] = useState("");
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const ACCESS_CODE = "4020"; // Replace this with your desired access code

    const MAX_CHARACTERS = 2048;

    const handleTextChange = (e) => {
        // Ensure the new text won’t exceed the maximum length
        setIsButtonDisabled(false);
        if (e.target.value.length <= MAX_CHARACTERS) {
            setQuestion(e.target.value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check the access code
        if (accessEmail == "") {
            alert("You must enter an email address to send the prompt results.");
            return;
        }
        //string to lower case
        if (accessChief.toLowerCase() != "roberts"){
            alert("You must enter the last name of the Chief Justice to send the prompt results.");
            return;
        }

        // Show loading text
        setIsButtonDisabled(true);
        setLoading(true);
        alert("Processing your request. This may take 10 - 30 minutes depending on current demand. You will receive an email with the model's output.");
        try {
            const response = await axios.post("https://200kmodel.aijudge.io/get_answer", {
                question: question,
                email: accessEmail,
                notes: notes
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            // Update answer state
            setAnswer(response.data.answer);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            // Hide loading text
            setLoading(false);
        }
    };

    return (
        <div>

            <h1>Llama-2-13b tuned with 200,000 judicial opinions.</h1>
            <h3 style={{width:'50%', margin:'auto', textAlign:'center'}}>Type your legal question for a response from our model. Input is limited to 2048 characters.
            Processing can take 10 - 30 minutes depending on current demand. You will receive an email with the model's
            output. </h3>
            <h3>You may get a reasonable response. You may not. Either way you will have fun.</h3>
            <h3>Tip: These models tend to respond better when prompted with more detail. A paragraph prompt with some specificity will do much better than a simple sentence.</h3>
            <br/><br/>
            <form onSubmit={handleSubmit}>
        <textarea
            name="question"
            rows="4"
            cols="50"
            placeholder="Type your question here..."
            value={question}
            onChange={handleTextChange}
            maxLength={MAX_CHARACTERS}
        /><br/><br/>
                <p>{MAX_CHARACTERS - question.length} characters remaining</p>
                <br/><br/>
                <textarea
                    name="notes"
                    rows="2"
                    cols="50"
                    placeholder="Optional: Add your notes here... Copyright info etc.."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    maxLength={100}
                />
                <br/><br/>
                <input
                    type="email"
                    placeholder="Enter Email Address"
                    value={accessEmail}
                    onChange={(e) => setAccessEmail(e.target.value)}
                /><br/><br/>
                <p>Let's reduce some of the bot interaction: Enter the last name of the U.S. Chief Justice.</p>
                <input
                    type="text"
                    placeholder="Last name of the Chief Justice."
                    value={accessChief}
                    onChange={(e) => setAccessChief(e.target.value)}
                /><br/><br/>
                <input type="submit" value="Prompt" disabled={isButtonDisabled} />
            </form>

<h4>Your inputs may be used for further model training, monetization, etc. Do not submit PII or confidential information. </h4>
        </div>
    );
}

export default QuestionAnswering;