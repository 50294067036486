import React from 'react';
import './Home.css';

function Home() {
    return (
        <div className="home">
            <header className="home-header">
                <h1>AIJUDGE.IO</h1>
            </header>
            <section className="about">

                <p>Experimental AI & ML Solutions for Building Legal Research Tools</p>
            </section>
            <section className="services">

                <p>Try out our Llama-2 models fine tuned on judicial opinions for free. </p>
                <p><b>Check out AiBitration! Use AI to settle disputes! The demo and code is above. </b> </p>
                <p>Judicial Opinion Datasets & Trained Models Available for Purchase</p>
            </section>
            <section className="contact">
                <h3>Macon Apps</h3>
                <p><a href="mailto:jj@maconapps.com">Questions & Inquiries</a></p>

            </section>
            <footer className="home-footer">
                <p>&copy; 2023 Investor Direction LLC dba Macon Apps.</p>
            </footer>
        </div>
    );
}

export default Home;