import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import QuestionAnswering from "./100k";
import Home from "./Home";
import {Survey} from "survey-react-ui";
import SurveyComponent from "./SurveyComponent";
import About from "./About";
import AiBitration from "./aibitrationdemo";


// Example components to navigate to



function App() {

  useEffect(() => {
    ReactGA.initialize('G-KK4ZFPL3HX');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
      <Router>
        <div className="App">

            <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                  <li>
                  <Link to="/200kmodel">200k Model Demo</Link>
                </li>
                <li>
                  <Link to="/aibitration">AiBitration Demo</Link>
                </li>
                <li>
                  <Link to="/aibitrationnotebook">AiBitration Code</Link>
                </li>
                <li>
                  <Link to="https://data.aijudge.io">Datasets</Link>
                </li>
                <li>
                  <Link to="https://arbitration.aijudge.io">AiBitration</Link>
                </li>
                {/*<li>
                  <Link to="/research">Research</Link>
                </li>*/}
                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>
              </ul>
            </nav>


          <main>
            <Routes>

            <Route path="/about" element={<About/>} />
            <Route path="/200kmodel" element={<QuestionAnswering/>} />
              <Route path="/aibitrationnotebook" element={<AiBitrationNotebook/>}/>
              <Route path="/aibitration" element={<AiBitration/>}/>
              <Route path="/aibitrationnotebook" element={<AiBitrationNotebook/>}/>
            <Route path="/research" element={<SurveyComponent/>} />
            <Route path="/privacy" element={<PDFViewer/>} />
            <Route path="/" element={<Home/>} />
            </Routes>
          </main>
        </div>
      </Router>
  );
}

export default App;

const PDFViewer = () => {
  return (
      <div>
        <iframe src="AIJUDGEprivacy.pdf" width="100%" height="700px" />
      </div>
  );
};

const AiBitrationNotebook = () => {
  return (
      <div>
        <iframe src="aibitrationdemo.pdf" width="100%" height="700px" />
      </div>
  );
};
