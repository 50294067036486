// // // //
// // // // import React, { useState, useEffect } from 'react';
// // // // import axios from "axios";
// // // //
// // // // const getQuestions = async () => {
// // // //     try {
// // // //         const response = await axios.get('https://100kmodel.aijudge.io/get_questions');
// // // //         let resultsarr = JSON.parse(response.data.results);
// // // //         console.log(resultsarr[0].question)
// // // //         console.log(resultsarr[0].answer)
// // // //         console.log(resultsarr[1].answer)
// // // //         return resultsarr; // Return the response data
// // // //     } catch (error) {
// // // //         console.error("Error fetching questions:", error);
// // // //         throw error; // Rethrow the error to handle it higher in the component tree
// // // //     }
// // // // }
// // // //
// // // // const submitSurvey = async (id, ranking) => {
// // // //     try {
// // // //         const response = await axios.post('https://100kmodel.aijudge.io/submit_survey', {
// // // //             "id": id,
// // // //             "ranking": ranking,
// // // //         });
// // // //         return response;
// // // //     } catch (error) {
// // // //         console.error("Error submitting survey:", error);
// // // //         throw error;
// // // //     }
// // // // }
// // // //
// // // // const SurveyComponent = () => {
// // // //     const [questions, setQuestions] = useState([
// // // //         {
// // // //             question: 'Default Question 1',
// // // //             answer: 'Default Answer 1',
// // // //             id: 'Default id',
// // // //         },
// // // //         {
// // // //             question: 'Default Question 2',
// // // //             answer: 'Default Answer 2',
// // // //             id: 'Default id',
// // // //         },
// // // //     ]);
// // // //     const [preference, setPreference] = useState('Neither');
// // // //     //const [surveyId, setSurveyId] = useState(0); // Track the current survey ID
// // // //
// // // //     useEffect(() => {
// // // //         // Call getQuestions when the component mounts to fetch survey questions
// // // //         getQuestions()
// // // //             .then((data) => {
// // // //                 setQuestions(data);
// // // //             })
// // // //             .catch((error) => {
// // // //                 console.error("Error fetching questions:", error);
// // // //             });
// // // //     }, []);
// // // //
// // // //     const handleNextSurvey = () => {
// // // //         // Increment the survey ID to load the next survey
// // // //         getQuestions()
// // // //             .then((data) => {
// // // //                 setQuestions(data);
// // // //             })
// // // //             .catch((error) => {
// // // //                 console.error("Error fetching questions:", error);
// // // //             });
// // // //         setPreference('Neither'); // Reset preference for the new survey
// // // //     };
// // // //
// // // //     const handlePreferenceChange = (text) => {
// // // //         setPreference(text);
// // // //     };
// // // //
// // // //     const handleSurveySubmit = () => {
// // // //         // Call the submitSurvey function with the current survey ID and preference
// // // //         submitSurvey(questions[0].id, preference + 1)
// // // //             .then((response) => {
// // // //                 // Handle the response if needed
// // // //                 console.log("Survey submitted:", response);
// // // //                 handleNextSurvey(); // Move to the next survey after submission
// // // //             })
// // // //             .catch((error) => {
// // // //                 console.error("Error submitting survey:", error);
// // // //             });
// // // //     };
// // // //
// // // //     return (
// // // //         <div className="survey-component">
// // // //             <h1>Model Response Survey</h1>
// // // //             <h3>Please select the large language model generated response that better answers the question.
// // // //             1 indicates Answer A is the most reasonable. 5 indicates Answer B is the most reasonable.</h3>
// // // //             {questions && (
// // // //                 <div>
// // // //                     <h2>Question</h2>
// // // //                     <div className="products">
// // // //                         {questions[0].question}
// // // //                     </div>
// // // //                     <h2>Answer A</h2>
// // // //                     <div className="products">
// // // //                         {questions[0].answer.slice(0, 1024)+"..."}
// // // //                     </div>
// // // //                     <h2>Answer B</h2>
// // // //                     <div className="products">
// // // //                         {questions[1].answer.slice(0, 1024)+"..."}
// // // //                     </div>
// // // //
// // // //                     <div className="button-group">
// // // //                         {['1 - Answer A', 2, 'Neither', 4, '5 - Answer B'].map((text, index) => (
// // // //                             <button
// // // //                                 key={text}
// // // //                                 onClick={() => handlePreferenceChange(index)}
// // // //                                 className={preference === index ? 'selected' : ''}
// // // //                             >
// // // //                                 {text}
// // // //                             </button>
// // // //                         ))}
// // // //                     </div>
// // // //
// // // //
// // // //
// // // //                     <button onClick={handleSurveySubmit}>Submit</button>
// // // //                 </div>
// // // //             )}
// // // //
// // // //
// // // //
// // // //
// // // //         </div>
// // // //     );
// // // // };
// // // //
// // // // export default SurveyComponent;
// // //
// // // import React, { useState, useEffect } from 'react';
// // // import axios from "axios";
// // //
// // // const getQuestions = async () => {
// // //     try {
// // //         const response = await axios.get('https://100kmodel.aijudge.io/get_questions');
// // //         let resultsarr = JSON.parse(response.data.results);
// // //         return resultsarr; // Return the response data
// // //     } catch (error) {
// // //         console.error("Error fetching questions:", error);
// // //         throw error; // Rethrow the error to handle it higher in the component tree
// // //     }
// // // }
// // //
// // // const submitSurvey = async (id, ranking) => {
// // //     try {
// // //         const response = await axios.post('https://100kmodel.aijudge.io/submit_survey', {
// // //             "id": id,
// // //             "ranking": ranking,
// // //         });
// // //         return response;
// // //     } catch (error) {
// // //         console.error("Error submitting survey:", error);
// // //         throw error;
// // //     }
// // // }
// // //
// // // const SurveyComponent = () => {
// // //     const [questions, setQuestions] = useState([]);
// // //     const [preference, setPreference] = useState('Neither');
// // //     const [showConfirmation, setShowConfirmation] = useState(false);
// // //
// // //     useEffect(() => {
// // //         // Call getQuestions when the component mounts to fetch survey questions
// // //         getQuestions()
// // //             .then((data) => {
// // //                 setQuestions(data);
// // //             })
// // //             .catch((error) => {
// // //                 console.error("Error fetching questions:", error);
// // //             });
// // //     }, []);
// // //
// // //     const handleNextSurvey = () => {
// // //         // Increment the survey ID to load the next survey
// // //         getQuestions()
// // //             .then((data) => {
// // //                 setQuestions(data);
// // //                 setShowConfirmation(false); // Hide the confirmation message
// // //             })
// // //             .catch((error) => {
// // //                 console.error("Error fetching questions:", error);
// // //             });
// // //         setPreference('Neither'); // Reset preference for the new survey
// // //     };
// // //
// // //     const handlePreferenceChange = (text) => {
// // //         setPreference(text);
// // //     };
// // //
// // //     const handleSurveySubmit = () => {
// // //         // Call the submitSurvey function with the current survey ID and preference
// // //         submitSurvey(questions[0].id, preference + 1)
// // //             .then((response) => {
// // //                 // Handle the response if needed
// // //                 console.log("Survey submitted:", response);
// // //                 setShowConfirmation(true); // Show the confirmation message
// // //             })
// // //             .catch((error) => {
// // //                 console.error("Error submitting survey:", error);
// // //             });
// // //     };
// // //
// // //     const handleAnswerAnotherQuestion = () => {
// // //         handleNextSurvey(); // Load another question
// // //     };
// // //
// // //     return (
// // //         <div className="survey-component">
// // //             <h1>Model Response Survey</h1>
// // //             <h3>Please select the large language model generated response that better answers the question.
// // //                 1 indicates Answer A is the most reasonable. 5 indicates Answer B is the most reasonable.</h3>
// // //             {questions.length > 0 && (
// // //                 <div>
// // //                     <h2>Question</h2>
// // //                     <div className="products">
// // //                         {questions[0].question}
// // //                     </div>
// // //                     <h2>Answer A</h2>
// // //                     <div className="products">
// // //                         {questions[0].answer.slice(0, 1024) + "..."}
// // //                     </div>
// // //                     <h2>Answer B</h2>
// // //                     <div className="products">
// // //                         {questions[1].answer.slice(0, 1024) + "..."}
// // //                     </div>
// // //
// // //                     <div className="button-group">
// // //                         {['1 - Answer A', 2, 'Neither', 4, '5 - Answer B'].map((text, index) => (
// // //                             <button
// // //                                 key={text}
// // //                                 onClick={() => handlePreferenceChange(index)}
// // //                                 className={preference === index ? 'selected' : ''}
// // //                             >
// // //                                 {text}
// // //                             </button>
// // //                         ))}
// // //                     </div>
// // //
// // //                     {showConfirmation ? (
// // //                         <div>
// // //                             <p>Thank you for your response!</p>
// // //                             <button onClick={handleAnswerAnotherQuestion}>Answer Another Question?</button>
// // //                         </div>
// // //                     ) : (
// // //                         <button onClick={handleSurveySubmit}>Submit</button>
// // //                     )}
// // //                 </div>
// // //             )}
// // //         </div>
// // //     );
// // // };
// // //
// // // export default SurveyComponent;
// //
// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// //
// // const getQuestions = async () => {
// //     try {
// //         const response = await axios.get('https://100kmodel.aijudge.io/get_questions');
// //         let resultsarr = JSON.parse(response.data.results);
// //         return resultsarr; // Return the response data
// //     } catch (error) {
// //         console.error("Error fetching questions:", error);
// //         throw error; // Rethrow the error to handle it higher in the component tree
// //     }
// // }
// //
// // const submitSurvey = async (id, ranking, sessionCode) => {
// //     try {
// //         const response = await axios.post('https://100kmodel.aijudge.io/submit_survey', {
// //             "id": id,
// //             "ranking": ranking,
// //             "sessionCode": sessionCode, // Include the session code in the request
// //         });
// //         return response;
// //     } catch (error) {
// //         console.error("Error submitting survey:", error);
// //         throw error;
// //     }
// // }
// //
// // const SurveyComponent = () => {
// //     const [questions, setQuestions] = useState([]);
// //     const [preference, setPreference] = useState('Neither');
// //     const [sessionCode, setSessionCode] = useState(''); // State for session code
// //     const [showConfirmation, setShowConfirmation] = useState(false);
// //
// //     useEffect(() => {
// //         // Call getQuestions when the component mounts to fetch survey questions
// //         getQuestions()
// //             .then((data) => {
// //                 setQuestions(data);
// //             })
// //             .catch((error) => {
// //                 console.error("Error fetching questions:", error);
// //             });
// //     }, []);
// //
// //     const handleNextSurvey = () => {
// //         // Increment the survey ID to load the next survey
// //         getQuestions()
// //             .then((data) => {
// //                 setQuestions(data);
// //                 setShowConfirmation(false); // Hide the confirmation message
// //             })
// //             .catch((error) => {
// //                 console.error("Error fetching questions:", error);
// //             });
// //         setPreference('Neither'); // Reset preference for the new survey
// //     };
// //
// //     const handlePreferenceChange = (text) => {
// //         setPreference(text);
// //     };
// //
// //     const handleSessionCodeChange = (event) => {
// //         setSessionCode(event.target.value);
// //     };
// //
// //     const handleSurveySubmit = () => {
// //         // Call the submitSurvey function with the current survey ID, preference, and session code
// //         submitSurvey(questions[0].id, preference + 1, sessionCode)
// //             .then((response) => {
// //                 // Handle the response if needed
// //                 console.log("Survey submitted:", response);
// //                 setShowConfirmation(true); // Show the confirmation message
// //             })
// //             .catch((error) => {
// //                 console.error("Error submitting survey:", error);
// //             });
// //     };
// //
// //     const handleAnswerAnotherQuestion = () => {
// //         handleNextSurvey(); // Load another question
// //     };
// //
// //     return (
// //         <div className="survey-component">
// //             <h1>Model Response Survey</h1>
// //             <h3>Please enter your session code and select the large language model generated response that better answers the question.
// //                 1 indicates Answer A is the most reasonable. 5 indicates Answer B is the most reasonable.</h3>
// //
// //             <div>
// //                 <label htmlFor="sessionCode">Session Code:</label>
// //                 <input
// //                     type="text"
// //                     id="sessionCode"
// //                     value={sessionCode}
// //                     onChange={handleSessionCodeChange}
// //                 />
// //             </div>
// //
// //             {questions.length > 0 && (
// //                 <div>
// //                     <h2>Question</h2>
// //                     <div className="products">
// //                         {questions[0].question}
// //                     </div>
// //                     <h2>Answer A</h2>
// //                     <div className="products">
// //                         {questions[0].answer.slice(0, 1024) + "..."}
// //                     </div>
// //                     <h2>Answer B</h2>
// //                     <div className="products">
// //                         {questions[1].answer.slice(0, 1024) + "..."}
// //                     </div>
// //
// //                     <div className="button-group">
// //                         {['1 - Answer A', 2, 'Neither', 4, '5 - Answer B'].map((text, index) => (
// //                             <button
// //                                 key={text}
// //                                 onClick={() => handlePreferenceChange(index)}
// //                                 className={preference === index ? 'selected' : ''}
// //                             >
// //                                 {text}
// //                             </button>
// //                         ))}
// //                     </div>
// //
// //                     {showConfirmation ? (
// //                         <div>
// //                             <p>Thank you for your response!</p>
// //                             <button onClick={handleAnswerAnotherQuestion}>Answer Another Question?</button>
// //                         </div>
// //                     ) : (
// //                         <button onClick={handleSurveySubmit}>Submit</button>
// //                     )}
// //                 </div>
// //             )}
// //         </div>
// //     );
// // };
// //
// // export default SurveyComponent;
//
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
//
// const getQuestions = async () => {
//     try {
//         const response = await axios.get('https://100kmodel.aijudge.io/get_questions');
//         let resultsarr = JSON.parse(response.data.results);
//         return resultsarr; // Return the response data
//     } catch (error) {
//         console.error("Error fetching questions:", error);
//         throw error; // Rethrow the error to handle it higher in the component tree
//     }
// }
//
// const submitSurvey = async (id, ranking, sessionCode) => {
//     try {
//         const response = await axios.post('https://100kmodel.aijudge.io/submit_survey', {
//             "id": id,
//             "ranking": ranking,
//             "sessionCode": sessionCode, // Include the session code in the request
//         });
//         return response;
//     } catch (error) {
//         console.error("Error submitting survey:", error);
//         throw error;
//     }
// }
//
// const SurveyComponent = () => {
//     const [questions, setQuestions] = useState([]);
//     const [preference, setPreference] = useState('Neither');
//     const [sessionCode, setSessionCode] = useState('');
//     const [showModal, setShowModal] = useState(true); // State for modal display
//     const [showConfirmation, setShowConfirmation] = useState(false);
//
//     useEffect(() => {
//         // Call getQuestions when the component mounts to fetch survey questions
//         getQuestions()
//             .then((data) => {
//                 setQuestions(data);
//             })
//             .catch((error) => {
//                 console.error("Error fetching questions:", error);
//             });
//     }, []);
//
//     const handleNextSurvey = () => {
//         // Increment the survey ID to load the next survey
//         getQuestions()
//             .then((data) => {
//                 setQuestions(data);
//                 setShowConfirmation(false); // Hide the confirmation message
//             })
//             .catch((error) => {
//                 console.error("Error fetching questions:", error);
//             });
//         setPreference('Neither'); // Reset preference for the new survey
//     };
//
//     const handlePreferenceChange = (text) => {
//         setPreference(text);
//     };
//
//     const handleSessionCodeChange = (event) => {
//         setSessionCode(event.target.value);
//     };
//
//     const handleSurveySubmit = () => {
//         // If the session code is not empty, proceed with submitting the survey
//         if (sessionCode.trim() !== '') {
//             // Call the submitSurvey function with the current survey ID, preference, and session code
//             submitSurvey(questions[0].id, preference + 1, sessionCode)
//                 .then((response) => {
//                     // Handle the response if needed
//                     console.log("Survey submitted:", response);
//                     setShowConfirmation(true); // Show the confirmation message
//                 })
//                 .catch((error) => {
//                     console.error("Error submitting survey:", error);
//                 });
//         }
//     };
//
//     const handleAnswerAnotherQuestion = () => {
//         handleNextSurvey(); // Load another question
//     };
//
//     return (
//         <div className="survey-component">
//             <h1>Model Response Survey</h1>
//             <h3>Please enter your session code and select the large language model generated response that better answers the question.
//                 1 indicates Answer A is the most reasonable. 5 indicates Answer B is the most reasonable.</h3>
//
//             {/* Modal for session code input */}
//             {showModal && (
//                 <div className="modal">
//                     <div className="modal-content">
//                         <h2>Session Code</h2>
//                         <input
//                             type="text"
//                             value={sessionCode}
//                             onChange={handleSessionCodeChange}
//                             placeholder="Enter your session code"
//                         />
//                         <button onClick={() => setShowModal(false)}>Submit</button>
//                     </div>
//                 </div>
//             )}
//
//             {!showModal && questions.length > 0 && (
//                 <div>
//                     <h2>Question</h2>
//                     <div className="products">
//                         {questions[0].question}
//                     </div>
//                     <h2>Answer A</h2>
//                     <div className="products">
//                         {questions[0].answer.slice(0, 1024) + "..."}
//                     </div>
//                     <h2>Answer B</h2>
//                     <div className="products">
//                         {questions[1].answer.slice(0, 1024) + "..."}
//                     </div>
//
//                     <div className="button-group">
//                         {['1 - Answer A', 2, 'Neither', 4, '5 - Answer B'].map((text, index) => (
//                             <button
//                                 key={text}
//                                 onClick={() => handlePreferenceChange(index)}
//                                 className={preference === index ? 'selected' : ''}
//                             >
//                                 {text}
//                             </button>
//                         ))}
//                     </div>
//
//                     {showConfirmation ? (
//                         <div>
//                             <p>Thank you for your response!</p>
//                             <button onClick={handleAnswerAnotherQuestion}>Answer Another Question?</button>
//                         </div>
//                     ) : (
//                         <button onClick={handleSurveySubmit}>Submit</button>
//                     )}
//                 </div>
//             )}
//         </div>
//     );
// };
//
// // export default SurveyComponent;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const getQuestions = async () => {
    try {
        const response = await axios.get('https://100kmodel.aijudge.io/get_questions');
        let resultsarr = JSON.parse(response.data.results);
        return resultsarr; // Return the response data
    } catch (error) {
        console.error("Error fetching questions:", error);
        throw error; // Rethrow the error to handle it higher in the component tree
    }
}

const submitSurvey = async (id, ranking, sessionCode) => {
    try {
        const response = await axios.post('https://100kmodel.aijudge.io/submit_survey', {
            "id": id,
            "ranking": ranking,
            "sessioncode": sessionCode, // Include the session code in the request
        });
        return response;
    } catch (error) {
        console.error("Error submitting survey:", error);
        throw error;
    }
}

const SurveyComponent = () => {
    const [questions, setQuestions] = useState([]);
    const [preference, setPreference] = useState('Neither');
    const [sessionCode, setSessionCode] = useState('');
    const [showModal, setShowModal] = useState(true); // State for modal display
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        // Call getQuestions when the component mounts to fetch survey questions
        getQuestions()
            .then((data) => {
                setQuestions(data);
            })
            .catch((error) => {
                console.error("Error fetching questions:", error);
            });
    }, []);

    const handleNextSurvey = () => {
        // Increment the survey ID to load the next survey
        getQuestions()
            .then((data) => {
                setQuestions(data);
                setShowConfirmation(false); // Hide the confirmation message
            })
            .catch((error) => {
                console.error("Error fetching questions:", error);
            });
        setPreference('Neither'); // Reset preference for the new survey
    };

    const handlePreferenceChange = (text) => {
        setPreference(text);
    };

    const handleSessionCodeChange = (event) => {
        setSessionCode(event.target.value);
    };

    const handleSessionCodeSubmit = () => {
        // When the user submits the session code, close the modal and show the main survey
        setShowModal(false);
    };

    const handleSurveySubmit = () => {
        // If the session code is not empty, proceed with submitting the survey
        if (sessionCode.trim() !== '') {
            // Call the submitSurvey function with the current survey ID, preference, and session code
            let questionId = questions[1].id
            if(preference == 0 || preference == 1 || preference == 2){
                questionId = questions[0].id
            }

            submitSurvey(questions[0].id + " " + questions[1].id + " | winner: " + questionId, preference + 1, sessionCode)
                .then((response) => {
                    // Handle the response if needed
                    console.log("Survey submitted:", response);
                    setShowConfirmation(true); // Show the confirmation message
                })
                .catch((error) => {
                    console.error("Error submitting survey:", error);
                });
        }
    };

    const handleAnswerAnotherQuestion = () => {
        handleNextSurvey(); // Load another question
    };

    return (
        <div className="survey-component">
            <h1>Model Response Survey</h1>

            {showModal ? (
                <div className="modal" style={modalStyle}>
                    <div className="modal-content">
                        <h2>Enter Your Session Code</h2>
                        <input
                            type="text"
                            value={sessionCode}
                            onChange={handleSessionCodeChange}
                            placeholder="Session Code"
                        />
                        <button onClick={handleSessionCodeSubmit}>Submit</button>
                    </div>
                </div>
            ) : (
                <>
                    <h3>Please select the large language model generated response that better answers the question.
                        1 indicates Answer A is the most reasonable. 7 indicates Answer B is the most reasonable.</h3>

                    {questions.length > 0 && (
                        <div>
                            <h2>Question</h2>
                            <div className="products">
                                {questions[0].question}
                            </div>
                            <div>
                                {questions[0].notes}
                            </div>

                            <h2>Answer A</h2>
                            <div className="products">
                                {questions[0].answer.slice(0, 2048) + "..."}
                            </div>
                            <h2>Answer B</h2>
                            <div className="products">
                                {questions[1].answer.slice(0, 2048) + "..."}
                            </div>

                            <div className="button-group">
                                {['1 - Answer A', 2,3,'Neither', 5,6, '7 - Answer B'].map((text, index) => (
                                    <button
                                        key={text}
                                        onClick={() => handlePreferenceChange(index)}
                                        className={preference === index ? 'selected' : ''}
                                    >
                                        {text}
                                    </button>
                                ))}
                            </div>

                            {showConfirmation ? (
                                <div>
                                    <p>Thank you for your response!</p>
                                    <button onClick={handleAnswerAnotherQuestion}>Answer Another Question?</button>
                                </div>
                            ) : (
                                <button onClick={handleSurveySubmit}>Submit</button>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const modalStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export default SurveyComponent;
