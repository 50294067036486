import React, { useState, useEffect } from "react";
import axios from "axios";

function AiBitration() {
    const [partyAArguments, setPartyAArguments] = useState("");
    const [partyBArguments, setPartyBArguments] = useState("");
    const [accessEmail, setAccessEmail] = useState("");
    const [accessChief, setAccessChief] = useState("");
    const [notes, setNotes] = useState("");
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const MAX_CHARACTERS = 2048;

    useEffect(() => {
        // Enable the button if either of the text boxes has text
        setIsButtonDisabled(partyAArguments.length === 0 && partyBArguments.length === 0);
    }, [partyAArguments, partyBArguments]); // Dependency array to trigger effect on changes

    const handlePartyAChange = (e) => {
        setPartyAArguments(e.target.value);
    };

    const handlePartyBChange = (e) => {
        setPartyBArguments(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (accessEmail === "") {
            alert("You must enter an email address.");
            return;
        }

        if (accessChief.toLowerCase() !== "roberts") {
            alert("Incorrect Chief Justice last name.");
            return;
        }

        setLoading(true);

        const combinedQuestion = `Which party presents the best case: \n Party A's arguments are:\n${partyAArguments}\nParty B's arguments are:\n${partyBArguments}\n`;

        try {
            const response = await axios.post("https://testrefmodel.aijudge.io/get_answer", {
                question: combinedQuestion,
                email: accessEmail,
                notes: notes
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            setAnswer(response.data.answer);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
            setIsButtonDisabled(true);
        }
    };

    return (
        <div>
            <h1>AiBitration Demo</h1>
            <h3 style={{ width: '50%', margin: 'auto', textAlign: 'center' }}>
                Type your sample brief for each party to see who wins. Input is limited to 2048 characters.
                Processing can take 10 - 30 minutes depending on current demand.
                You will receive an email with the model's output.
            </h3>
            <h3>You may get a reasonable response. You may not. Either way you will have fun.</h3>
            <h3>
                Tip: These models tend to respond better when prompted with more detail. A paragraph prompt with some specificity will do much better than a simple sentence.
            </h3>
            <br /><br />
            <form onSubmit={handleSubmit}>
                <textarea
                    name="partyAArguments"
                    rows="4"
                    cols="50"
                    placeholder="Type Party A's arguments here..."
                    value={partyAArguments}
                    onChange={handlePartyAChange}
                />
                <p>{MAX_CHARACTERS - partyAArguments.length} characters remaining for Party A</p>
                <br /><br />
                <textarea
                    name="partyBArguments"
                    rows="4"
                    cols="50"
                    placeholder="Type Party B's arguments here..."
                    value={partyBArguments}
                    onChange={handlePartyBChange}
                />
                <p>{MAX_CHARACTERS - partyBArguments.length} characters remaining for Party B</p>
                <br /><br />
                <textarea
                    name="notes"
                    rows="2"
                    cols="50"
                    placeholder="Optional - Internal Testers: Add your notes here..."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    maxLength={100}
                />
                <br /><br />
                <input
                    type="email"
                    placeholder="Enter Email Address"
                    value={accessEmail}
                    onChange={(e) => setAccessEmail(e.target.value)}
                /><br /><br />
                <p>Let's reduce some of the bot interaction: Enter the last name of the U.S. Chief Justice.</p>
                <input
                    type="text"
                    placeholder="Last name of the Chief Justice."
                    value={accessChief}
                    onChange={(e) => setAccessChief(e.target.value)}
                /><br /><br />
                <input type="submit" value="Prompt" disabled={isButtonDisabled} />
            </form>
            {loading && <p>Loading...</p>}
            <p>{answer}</p>
            <h4>Your inputs may be used for further model training, monetization, etc. Do not submit PII or confidential information.</h4>
        </div>
    );
}

export default AiBitration;
