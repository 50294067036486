import React from 'react';
import './About.css'; // Make sure to create an About.css file in the same directory

function About() {
    return (
        <div className="about-container">
            <h2>Macon Apps</h2>
            <p>
                Macon Apps places a significant emphasis on large language model research
                due to the transformative potential it holds for the legal industry.
            </p>
            <p>
                This site is a hub for our research and development efforts. Datasets and models available. Please contact for custom solutions or check out the Marketplace for fast integration.

            </p>
        </div>
    );
}

export default About;